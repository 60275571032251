/**
 * PROJECT.ACTIONS
 */

import {
  Project,
  ProjectAxisRule,
  ProjectDiffusionMode,
  ProjectMessageOptions,
  ProjectQrcodeOptions,
  ProjectStep,
  PROJECT_ACTIVATE,
  PROJECT_EDIT,
  PROJECT_EDIT_ALERTS,
  PROJECT_EDIT_AXIS_RULE,
  PROJECT_EDIT_MESSAGE_OPTIONS,
  PROJECT_EDIT_QRCODE_OPTIONS,
  PROJECT_GET,
  PROJECT_INIT,
  PROJECT_REMOVE,
  PROJECT_STATUS,
  StepAlert
} from "./project.types"
import { Template } from "./template.types"
import { StatusType } from "./_status.types"
import { Population } from "./population.types"
import { Topic } from "./topic.types"
import { v4 as uuid } from "uuid"
import { Axis } from "./axis.types"
import { flatten, uniqBy } from "lodash"
import { Email } from "./email.types"
import { Sending } from "./sending.types"
import { User } from "./user.types"
import { Filter } from "./filter.types"
import { t } from "@/translate/t"
import { store } from ".."
import i18n from "@/translate/i18n"
import { emailUpdate, emailUpdate_AsSupervisor } from "./email.actions"

//REDUX

export const projectActivate = (project: Project) => ({
  type: PROJECT_ACTIVATE,
  payload: {
    project
  }
})

export const projectEdit = (key: string, value: any) => ({
  type: PROJECT_EDIT,
  payload: {
    key,
    value
  }
})

export const projectEditAlerts = (key: ProjectStep, value: StepAlert) => ({
  type: PROJECT_EDIT_ALERTS,
  payload: {
    key,
    value
  }
})

export const projectEditAxisRule = (axisId: string, populations: Population[]) => ({
  type: PROJECT_EDIT_AXIS_RULE,
  payload: {
    axisId,
    populations
  }
})

export const projectEditMessageOptions = (key: string, value: any) => ({
  type: PROJECT_EDIT_MESSAGE_OPTIONS,
  payload: {
    key,
    value
  }
})

export const projectEditQrcodeOptions = (key: string, value: any) => ({
  type: PROJECT_EDIT_QRCODE_OPTIONS,
  payload: {
    key,
    value
  }
})

export const projectGet = (projects: Project[]) => ({
  type: PROJECT_GET,
  payload: {
    projects
  }
})

export const projectInit = () => ({
  type: PROJECT_INIT
})

export const projectRemove = (id: string) => ({
  type: PROJECT_REMOVE,
  payload: {
    id
  }
})

export const projectStatus = (status: StatusType) => ({
  type: PROJECT_STATUS,
  payload: {
    status
  }
})

//API

export const projectDestroy = (projectId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/project/" + projectId
  }
})

export const projectDuplicate_AsSupervisor = (
  projectId: string,
  languages: string[],
  isSpecific: boolean,
  specificAccounts: string[]
) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/project/duplicate",
    data: {
      projectId,
      languages,
      isSpecific,
      specificAccounts
    }
  }
})

export const projectFetchAll = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/projects"
  }
})

export const projectFetchOne = (projectId: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/public/project",
    data: {
      projectId
    }
  }
})

export const projectLaunch = (
  projectId: string,
  isTest: boolean,
  surveyName: string,
  language: string,
  emailId: string | null
) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/project/launch",
    data: {
      projectId,
      isTest,
      surveyName,
      language,
      emailId
    }
  }
})

export const projectUpdate = (project: Project) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/project",
    data: {
      project
    }
  }
})

export const projectUpdate_AsSupervisor = (project: Project, accountId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/project",
    data: {
      project,
      accountId
    }
  }
})

//CHECK MODES
export const projectCheckModes: Function = (isDiffusionModeDefined: boolean) => async (dispatch) => {
  const errors: string[] = []

  if (!isDiffusionModeDefined) {
    errors.push("project_error_modes_no_mode")
  }

  dispatch(projectEditAlerts("modes", new StepAlert({ errors, warnings: [] })))
}

//CHECK TEMPLATE
export const projectCheckTemplate: Function =
  (template: Template, topics: Topic[], axes: Axis[], languages: string[]) => async (dispatch) => {
    const errors: string[] = []
    const warnings: string[] = []

    //Type topics et axes
    topics = topics.map((x) => new Topic(x))
    axes = axes.map((x) => new Axis(x))

    //indicators to evaluate
    const topicsCount: number = topics.length
    const axesCount: number = axes.length

    //check errors
    if (!template.id) {
      errors.push("project_error_template_no_template")
    } else {
      //Loop on every language
      languages.forEach((language) => {
        //Loop on every topic
        let undefinedTopicsCount = 0
        topics.forEach((topic) => {
          //Question without text
          const undefinedQuestionsCount = topic.getEmptyQuestions(language).length
          if (undefinedQuestionsCount > 0 && !topic.getIsEmpty(language)) {
            if (languages.length > 1) {
              errors.push(
                t("project_error_template_question_untranslate" + (undefinedQuestionsCount > 1 ? "_multiple" : ""), {
                  country: language.toUpperCase(),
                  topic: topic.name[language],
                  count: undefinedQuestionsCount
                })
              )
            } else {
              errors.push(
                t("project_error_template_question_unnamed" + (undefinedQuestionsCount > 1 ? "_multiple" : ""), {
                  topic: topic.name[language],
                  count: undefinedQuestionsCount
                })
              )
            }
          }

          //Get topic not defined
          if (topic.getIsEmpty(language)) {
            undefinedTopicsCount += 1
          }
        })

        //Text for topic not defined
        if (undefinedTopicsCount > 0) {
          if (languages.length > 1) {
            errors.push(
              t("project_error_template_topic_untranslate" + (undefinedTopicsCount > 1 ? "_multiple" : ""), {
                count: undefinedTopicsCount,
                country: language.toUpperCase(),
                topics: topics
                  .filter((x) => x.getIsEmpty(language))
                  .map((x) => x.label)
                  .join(", ")
              })
            )
          } else {
            errors.push(
              t("project_error_template_topic_unnamed" + (undefinedTopicsCount > 1 ? "_multiple" : ""), {
                count: undefinedTopicsCount
              })
            )
          }
        }
      })

      //Count of draft questions
      let countDraft = 0
      topics.forEach((topic) => {
        countDraft += topic.countDraft
      })
      if (countDraft) {
        errors.push(
          t("project_error_template_draft_question" + (countDraft > 1 ? "_multiple" : ""), {
            count: countDraft
          })
        )
      }

      //Errors about the questionnaire
      if (axesCount < 1) {
        errors.push("project_error_template_no_axis")
      }
      if (topicsCount < 1) {
        errors.push("project_error_template_no_question")
      }
    }

    dispatch(projectEditAlerts("template", new StepAlert({ errors, warnings })))
  }

//CHECK RECIPIENTS
export const projectCheckRecipients: Function =
  (
    usersWithoutEmail: User[],
    countWithoutEmail: number,
    usersWithoutAddress: User[],
    countWithoutAddress: number,
    usersCount: number,
    participationMin: number
  ) =>
  async (dispatch) => {
    const errors: string[] = []
    const warnings: string[] = []

    //If user count is null
    if (usersCount === 0) {
      errors.push("project_error_recipients_no_user")
    }

    //If there is not enough users
    if (usersCount < participationMin) {
      warnings.push(
        t("project_error_recipients_too_few_users", {
          min: participationMin
        })
      )
    }

    //If there is users without email that are referenced
    if (countWithoutEmail > 0) {
      warnings.push(
        t("project_error_recipients_emailless_users" + (countWithoutEmail > 1 ? "_multiple" : ""), {
          count: countWithoutEmail,
          list: usersWithoutEmail.map((x) => x.username).join(", ") + (countWithoutEmail > 3 ? "..." : "")
        }) +
          " " +
          t("project_error_recipients_emailless_users_help")
      )
    }

    //If there is users without adress
    if (countWithoutAddress > 0) {
      warnings.push(
        t("project_error_recipients_no_address_users" + (countWithoutAddress > 1 ? "_multiple" : ""), {
          count: countWithoutAddress,
          list: usersWithoutAddress.map((x) => x.username).join(", ") + (countWithoutAddress > 3 ? "..." : "")
        }) +
          " " +
          t("project_error_recipients_no_address_users_help")
      )
    }

    dispatch(projectEditAlerts("recipients", new StepAlert({ errors, warnings })))
  }

//CHECK SEGMENT
export const projectCheckSegment: Function =
  (segmentationByAxesRules: ProjectAxisRule[], populations: Population[]) => async (dispatch) => {
    const errors: string[] = []
    const warnings: string[] = []

    if (checkAbsentPopulations().length > 0) {
      errors.push("project_error_segment_missing_population")
    }

    function checkAbsentPopulations(): Population[] {
      const uselessPopulations: Population[] = []
      const axisPopulations: Population[] = uniqBy(
        flatten(segmentationByAxesRules.map((x: ProjectAxisRule) => x.populations)),
        "id"
      )

      axisPopulations.forEach((axisPop: Population) => {
        const commonFilterPopulations: Population[] = populations.filter(
          (projectPop: Population) => projectPop.filterName === axisPop.filterName
        )
        if (
          commonFilterPopulations.length > 0 &&
          !commonFilterPopulations.find((projectPop: Population) => projectPop.name === axisPop.name)
        ) {
          uselessPopulations.push(axisPop)
        }
      })

      return uselessPopulations
    }

    dispatch(projectEditAlerts("segment", new StepAlert({ errors, warnings })))
  }

//CHECK LANGUAGE
export const projectCheckLanguages: Function = (languages: string[]) => async (dispatch) => {
  const errors: string[] = []

  //check errors
  if (languages.length < 1) {
    errors.push("project_error_languages_no_language")
  }

  dispatch(projectEditAlerts("languages", new StepAlert({ errors, warnings: [] })))
}

//CHECK FILTERS
export const projectCheckFilters: Function = (filters: Filter[], participationMin: number) => async (dispatch) => {
  const warnings: string[] = []

  //Detect if there is a lot of populations with less than n participants (more than 25%)
  filters.forEach((filter) => {
    const populationWithoutResults = filter.populations.filter((x) => x.usersCount < participationMin)
    if (filter.populations.length) {
      if (populationWithoutResults.length / filter.populations.length > 0.25) {
        const populationSample = populationWithoutResults.slice(0, 3)
        warnings.push(
          t("project_warning_filters", {
            name: filter.name,
            min: participationMin,
            populations:
              populationSample.map((x) => x.name).join(",") + (populationWithoutResults.length > 3 ? "..." : "")
          })
        )
      }
    }
  })

  if (filters.some((filter: Filter) => !filter.isChecked && !filter.default)) {
    warnings.push("project_warning_filters_unchecked")
  }

  dispatch(projectEditAlerts("filters", new StepAlert({ errors: [], warnings })))
}

//CHECK MESSAGE
export const projectCheckMessage: Function =
  (languages: string[], messageOptions: ProjectMessageOptions) => async (dispatch) => {
    const errors: string[] = []
    const warnings: string[] = []

    //Check title
    if (languages.some((lang: string) => !messageOptions.title[lang] || messageOptions.title[lang].length < 1)) {
      warnings.push(
        languages.length > 1
          ? "project_warning_message_missing_title_language"
          : "project_warning_message_missing_title"
      )
    }

    //Check content
    if (languages.some((lang: string) => !messageOptions.content[lang] || messageOptions.content[lang].length < 1)) {
      warnings.push(
        languages.length > 1
          ? "project_warning_message_missing_content_language"
          : "project_warning_message_missing_content"
      )
    }

    //Check if info message is provided (if message content)
    if (
      languages.some(
        (lang: string) => !messageOptions.infoContent[lang] || messageOptions.infoContent[lang].length < 1
      ) &&
      messageOptions.isInfoCustom
    ) {
      warnings.push(
        languages.length > 1 ? "project_warning_message_missing_info_language" : "project_warning_message_missing_info"
      )
    }

    dispatch(projectEditAlerts("message", new StepAlert({ errors, warnings })))
  }

//CHECK QRCODE
export const projectCheckQrcode: Function =
  (languages: string[], qrcodeOptions: ProjectQrcodeOptions) => async (dispatch) => {
    const errors: string[] = []
    const warnings: string[] = []

    if (qrcodeOptions.senderAddress.length < 1 && !qrcodeOptions.hideAddress) {
      warnings.push("project_warning_qrcode_missing_address")
    }

    if (qrcodeOptions.senderName.length < 1) {
      warnings.push("project_warning_qrcode_missing_name")
    }

    if (languages.some((lang: string) => !qrcodeOptions.content[lang] || qrcodeOptions.content[lang].length < 1)) {
      warnings.push("project_warning_qrcode_missing_content")
    }

    dispatch(projectEditAlerts("qrcode", new StepAlert({ errors, warnings })))
  }

//CHECK EMAIL
export const projectCheckEmails: Function = (languages: string[], emails: Email[]) => async (dispatch) => {
  const errors: string[] = []
  const warnings: string[] = []
  emails = emails.map((x) => new Email(x))

  if (emails.length === 0) {
    warnings.push("project_error_emails_no_email")
  }

  if (emails.some((email: Email) => !email.isChecked)) {
    warnings.push("project_warning_emails_not_checked")
  }

  if (emails.some((email: Email) => checkFieldEmptiness(email))) {
    errors.push("project_error_emails_empty_email")
  }

  if (emails.some((email: Email) => email.name.length === 0 || email.sender.length === 0)) {
    errors.push("project_error_emails_empty_name")
  }

  function checkFieldEmptiness(email: Email): boolean {
    return languages.some((lang: string) => email.getEmptyFields(lang).length > 0)
  }

  dispatch(projectEditAlerts("emails", new StepAlert({ errors, warnings })))
}

//CHECK CALENDAR
export const projectCheckCalendar: Function =
  (diffusionMode: ProjectDiffusionMode, sendings: Sending[]) => async (dispatch, getState) => {
    const errors: string[] = []
    const warnings: string[] = []

    if (diffusionMode.email) {
      if (sendings.length === 0) {
        warnings.push("project_error_calendar_no_sending")
      } else if (sendings.length === 1) {
        warnings.push("project_warning_calendar_no_sendings")
      } else if (sendings.length === 2) {
        warnings.push("project_warning_calendar_too_few_sendings_1")
      } else if (sendings.length < 5) {
        warnings.push(
          t("project_warning_calendar_too_few_sendings_2", {
            count: sendings.length - 1
          })
        )
      }
    }

    if (!getState().project.active.isLaunch) {
      if (
        sendings
          .map((sending: Sending) => sending.date)
          .sort((a: any, b: any) => {
            return a - b
          })[0] < new Date()
      ) {
        errors.push("project_error_calendar_sending_date_expired")
      }
    }

    dispatch(projectEditAlerts("calendar", new StepAlert({ errors, warnings })))
  }

export async function projectCreateNew(
  accountName: string,
  projectName: string,
  template: Template | null,
  accountId: string | null
) {
  const project = new Project()
  project.id = uuid()
  project.languages = [i18n.language]
  project.name = projectName
  project.messageOptions = project.getInitialMessageOptions(accountName, projectName)

  if (template) {
    project.TemplateId = template.id
  }

  //Update project in the database
  if (accountId) {
    await store.dispatch(projectUpdate_AsSupervisor(project, accountId))
  } else {
    await store.dispatch(projectUpdate(project))
  }

  //Add emails from template
  const email = new Email()
  const emails: Email[] = [email.getFromMessage(t("email_template_0"), project.messageOptions, accountName)].concat(
    email
      .getList(
        accountName,
        project.name,
        project.Template.label /*.length > 0 ? project.Template.label : t("template_tag_qvt")*/
      )
      .slice(1)
  )

  //Update in database (emails)
  for (let i = 0; i < emails.length; i++) {
    if (accountId) {
      await store.dispatch(emailUpdate_AsSupervisor(project.id, emails[i], accountId))
    } else {
      await store.dispatch(emailUpdate(project.id, emails[i]))
    }
  }

  //Set order of the emails
  project.emailsOrder = emails.map((x) => x.id)

  //Activate project
  return project
}
