/**
 * PROJECT-ADD.MODAL
 * Create new projet from a template
 */
import Modal from "@/components/modal"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { templateDuplicate } from "@/redux/template.actions"
import { Template, TemplateState } from "@/redux/template.types"
import { Session } from "@/redux/_session.types"
import LoadingModal from "./loading.modal"
import { projectActivate, projectCreateNew } from "@/redux/project.actions"

interface StateProps extends WithTranslation {
  _session: Session
  template: TemplateState
}

interface OwnProps {
  isWithoutTemplate?: boolean //Select from project page without defined template
  onClose: Function
}

type Props = StateProps & OwnProps

function ProjectAddModal(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [projectName, setProjectName] = useState(
    props.isWithoutTemplate
      ? t("project_new")
      : props.template.active.getDuplicateName(props._session.accountName, true)
  )
  const [isLoading, setIsLoading] = useState(false)

  //Click on confirm
  //If this is an idtree template duplicate it before
  async function next() {
    if (props.isWithoutTemplate) {
      insert(null)
    } else if (props.template.active.fromSupervisor) {
      setIsLoading(true)
      const templateResponse: any = await store.dispatch(templateDuplicate(props.template.active.id, projectName))

      if (!templateResponse.error) {
        insert(templateResponse)
      } else {
        setIsLoading(true)
      }
    } else {
      const template = new Template(props.template.active)
      template.name = { [props._session.language]: projectName }
      insert(template)
    }
  }

  //Insert in database and go to edit page
  async function insert(template) {
    const project = await projectCreateNew(props._session.accountName, projectName, template, null)

    //Activate project
    store.dispatch(projectActivate(project))

    //Go to project page
    navigate("/project/edit")
  }

  return (
    <Modal isCloseButtonVisible title={t("template_send")} onClose={() => props.onClose()} onNext={next}>
      {isLoading && <LoadingModal />}

      <div className="flex">
        <TextInput
          autoFocus
          onChange={(e) => setProjectName(e.value)}
          title={t("project_add_name")}
          value={projectName}
          help={t("project_add_name_help")}
        />

        <div className="width-40" />

        <img alt="send" src={require("@/assets/project-status/draft.png")} width="150" height="150" />
      </div>

      <div className="height-20" />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template
})

export default connect(mapStateToProps)(withTranslation()(ProjectAddModal))
