import { Email, EMAIL_ACTIVATE, EMAIL_ADD, EMAIL_EDIT, EMAIL_GET, EMAIL_INIT, EMAIL_REMOVE } from "./email.types"
import { projectCheckEmails, projectStatus } from "./project.actions"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types"

// REDUX

export const emailActivate = (email: Email) => ({
  type: EMAIL_ACTIVATE,
  payload: {
    email
  }
})

export const emailAdd = (email: Email) => ({
  type: EMAIL_ADD,
  payload: {
    email
  }
})

export const emailEdit = (key: string, value: any) => ({
  type: EMAIL_EDIT,
  payload: {
    key,
    value
  }
})

export const emailGet = (emails: Email[], emailsOrder: string[]) => ({
  type: EMAIL_GET,
  payload: {
    emails,
    emailsOrder
  }
})

export const emailInit = () => ({
  type: EMAIL_INIT
})

export const emailRemove = (id: string) => ({
  type: EMAIL_REMOVE,
  payload: {
    id
  }
})

// API

export const emailDestroy = (id: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/email/" + id
  }
})

export const emailFetchAll = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/emails/all"
  }
})

export const emailFetchByProject = (projectId: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/project/emails",
    data: {
      projectId
    }
  }
})

export const emailFetchByAccount = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/account/emails"
  }
})

export const emailSendTest = (emailId: string, emailAddress: string, language: string, skin: number | null) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/email/send-test",
    data: {
      emailId,
      emailAddress,
      language,
      skin
    }
  }
})

export const emailUpdate = (projectId: string, email: Email) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/email",
    data: {
      projectId,
      email
    }
  }
})

export const emailUpdate_AsSupervisor = (projectId: string, email: Email, accountId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/email",
    data: {
      projectId,
      email,
      accountId
    }
  }
})

export const emailSetIsCheckedAndUpdate: any = () => async (dispatch, getState) => {
  const errors: any[] = []

  dispatch(projectStatus(STATUS_SAVING))
  getState()
    .email.list.filter((x: Email) => !x.isChecked)
    .forEach((x: Email) => {
      x.isChecked = true
      dispatch(emailActivate(x))
      dispatch(emailEdit("isChecked", true))
      dispatch(emailUpdate(getState().project.active.id, x)).then((response: any) =>
        response.error ? errors.push(response.error) : {}
      )
    })
  dispatch(projectStatus(errors.length > 0 ? STATUS_SAVE_ERROR : STATUS_SAVED))
  dispatch(projectCheckEmails(getState().project.active.languages, getState().email.list))
}
