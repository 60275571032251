/**
 * ADD-ACCOUNT.MODAL
 * Add a new account
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { AccountState } from "@/redux/account.types"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { accountInsert_AsSupervisor, accountEdit, accountStatus } from "@/redux/account.actions"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Checkbox from "@/components/checkbox"
import { useEffect, useState } from "react"
import { templateFetchAndGet } from "@/redux/template.actions"
import { TemplateState } from "@/redux/template.types"
import Link from "@/components/link"
import { projectCreateNew } from "@/redux/project.actions"

interface StateProps extends WithTranslation {
  account: AccountState
  template: TemplateState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps

function AddAccountModal(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [createProject, setCreateProject] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<null | string>(null)

  //Load template
  useEffect(() => {
    store.dispatch(templateFetchAndGet(true))
  }, [])

  //Save account
  //When done go to page /supervisor/account
  async function next() {
    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(
      accountInsert_AsSupervisor(props.account.active, createProject ? selectedTemplateId : null)
    )

    if (response.error) {
      store.dispatch(accountStatus(STATUS_SAVE_ERROR))
    } else {
      if (createProject) {
        await projectCreateNew(
          props.account.active.name,
          "Enquête " + props.account.active.name,
          response.template,
          props.account.active.id
        )
      }

      store.dispatch(accountStatus(STATUS_SAVED))
      navigate("/supervisor/account")
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.account.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("account_create")}>
      <TextInput
        autoFocus
        isFullWidth
        onChange={(e) => store.dispatch(accountEdit("name", e.value))}
        title={t("account_name")}
        value={props.account.active.name}
      />

      <Checkbox
        active={createProject}
        text="Créer un projet pour le compte"
        onClick={() => setCreateProject(!createProject)}
      />

      {createProject && (
        <div>
          <p>
            <b>Quel template souhaitez-vous utiliser pour créer un projet ?</b>
          </p>

          <ul>
            {props.template.list
              .filter((x) => x.free)
              .map((template) => (
                <li key={template.id}>
                  <Link
                    onClick={() => setSelectedTemplateId(template.id === selectedTemplateId ? null : template.id)}
                    isWithoutMargin>
                    <span className={template.id === selectedTemplateId ? "orange-t" : ""}>{template.label}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      )}

      <div className="height-20" />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  account: state.account,
  template: state.template
})

export default connect(mapStateToProps)(withTranslation()(AddAccountModal))
