/**
 * DASHBOARD-NPS
 * NPS score for the survey
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { FilterState } from "@/redux/filter.types"
import { Session } from "@/redux/_session.types"
import { store } from "@/index"
import { SurveyState } from "@/redux/survey.types"
import { fetchNps } from "@/redux/_nps.actions"
import { Topic, TopicState } from "@/redux/topic.types"
import { Question } from "@/redux/question.types"
import PageLoader from "@/components/page-loader"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import Card from "@/components/card"
import { flatten, sortBy } from "lodash"
import DashboardNumber from "@/components/dashboard-number"
import { faFrown, faSmile, faUsers } from "@fortawesome/free-solid-svg-icons"
import DashboardRepartitionChartWidget from "./dashboard-repartition-chart.widget"
import Space from "@/components/space"
import DashboardNpsTrendsWidget from "./dashboard-nps-trends.widget"
import DashboardNpsMeterWidget from "./dashboard-nps-meter.widget"

interface StateProps extends WithTranslation {
  _session: Session
  filter: FilterState
  survey: SurveyState
  topic: TopicState
}

interface OwnProps {
  isOverview?: boolean
  isSmall?: boolean
}

type Props = StateProps & OwnProps

interface NpsQuestion {
  aid: number
  detractors: number
  detractorsRatio: number
  neutral: number
  neutralRatio: number
  nps: number
  promotors: number
  promotorsRatio: number
  total: number
}

const CEIL_BAD_SCORE: number = -10
const CEIL_GOOD_SCORE: number = 5

function DashboardNpsWidget(props: Props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [npsQuestions, setNpsQuestions] = useState<NpsQuestion[]>([])
  const [enpsQuestionAid, setEnpsQuestionAid] = useState<number>(0)

  //Load count of message every times filters are changed
  //All level should be greater than 2
  useEffect(() => {
    async function load() {
      setIsLoading(true)

      if (props._session.modules.nps && props.survey.active.id.length > 0) {
        const response: any = await store.dispatch(fetchNps(props.filter.dashboard))

        if (response.error || response.length < 1) {
          setNpsQuestions([])
          setEnpsQuestionAid(0)
        } else {
          setNpsQuestions(sortBy(response, "nps"))
          setEnpsQuestionAid(response[0].aid)
        }
      }

      setIsLoading(false)
    }

    load()
  }, [props.survey.active.id, props._session.modules.nps, props.survey.active.randomData, props.filter.dashboard])

  function getColor(questionNps) {
    if (questionNps >= CEIL_GOOD_SCORE) {
      return "#20CA7E"
    } else if (questionNps >= CEIL_BAD_SCORE) {
      return "#f2d600"
    } else {
      return "#eb5a46"
    }
  }

  function getQuestion(questionAid) {
    const questions = flatten(props.topic.list.map((x) => x.Questions))
    const question = questions.find((x) => x.aid === questionAid)
    return new Question(question)
  }

  function getTopic(questionAid) {
    const topic = props.topic.list.find((x) => x.Questions.map((q) => q.aid).indexOf(questionAid) > -1)
    return new Topic(topic)
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      {props.survey.active.randomData ? (
        <p className="grey-t">
          <b>{t("utils_unavailable")}</b>
        </p>
      ) : (
        <div>
          {!props.isOverview && <b className="grey-t">{t("nps_explanation")}</b>}

          <div className="height-20" />

          {!npsQuestions.length && (
            <p className="grey-t">
              <b>{t("nps_empty")}</b>
            </p>
          )}

          {npsQuestions.map((question: NpsQuestion) => (
            <div key={question.aid} className="flex" style={{ width: 1000 }}>
              <Card className="flex1" exportName={getQuestion(question.aid).label}>
                <div
                  style={{
                    width: props.isSmall ? 300 : undefined,
                    marginTop: props.isSmall ? -40 : undefined
                  }}>
                  <p>
                    <b>{getTopic(question.aid).label}</b>
                  </p>
                  <p>{getQuestion(question.aid).label}</p>
                </div>

                <div className="height-20" />

                <div className="flex">
                  <div className="flex flex-dcol" style={{ minHeight: 230 }}>
                    <div className="height-20" />

                    <div className="flex">
                      <Space />

                      <DashboardNpsMeterWidget
                        ceilBad={CEIL_BAD_SCORE}
                        ceilGood={CEIL_GOOD_SCORE}
                        score={question.nps}
                      />

                      <Space />
                    </div>

                    <Space />

                    <DashboardNumber
                      color={getColor(question.nps)}
                      isLoading={isLoading}
                      count={question.nps.toFixed(0)}
                      icon={question.nps >= 0 ? faSmile : faFrown}
                      header={t("nps_header")}
                      title={t("nps")}
                      fontSize={48}
                    />
                  </div>

                  <Space />

                  {!props.isSmall && <DashboardRepartitionChartWidget questionNpsAid={question.aid} />}

                  <Space />

                  {!props.isSmall && (
                    <div className="flex flex-dcol">
                      <Space />

                      <DashboardNumber
                        isLoading={isLoading}
                        count={question.total}
                        icon={faUsers}
                        header={t("nps_users_header")}
                        title={t("nps_users")}
                        fontSize={22}
                      />

                      <Space />
                    </div>
                  )}
                </div>
              </Card>

              {!props.isOverview &&
                (enpsQuestionAid === question.aid ? (
                  <div style={{ width: 250, padding: "0 0 40px 30px" }}>
                    <DashboardNpsTrendsWidget ceilBad={CEIL_BAD_SCORE} ceilGood={CEIL_GOOD_SCORE} />
                  </div>
                ) : (
                  <div style={{ width: 280 }} />
                ))}
            </div>
          ))}
        </div>
      )}
    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsWidget))
