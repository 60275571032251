/**
 * DASHBOARD-MESSAGE.WIDGET
 * Display message
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Highlighter from "react-highlight-words"
import { useState } from "react"
import { Message, MessageState } from "@/redux/message.types"
import { store } from "@/index"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { Question } from "@/redux/question.types"
import ListButton from "@/components/list-button"
import { faTrash, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import Space from "@/components/space"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModalConfirm from "@/components/modal-confirm"
import { messageDestroy, messageStatus } from "@/redux/message.actions"
import { SurveyState } from "@/redux/survey.types"
import { toast } from "react-toastify"
import { Topic } from "@/redux/topic.types"
import { topicActivate, topicEditQuestion } from "@/redux/topic.actions"
import ColorPickerWidget from "@/widgets/color-picker.widget"
import { Session } from "@/redux/_session.types"
import { Tag } from "@/redux/account.types"

interface StateProps extends WithTranslation {
  _session: Session
  message: MessageState
  survey: SurveyState
}

interface OwnProps {
  currentQuestion: Question
  currentMessage: Message
  currentTopic: Topic
  search: string
  id?: string
  onSetColor: Function
}

type Props = StateProps & OwnProps

const MODAL_DELETE = "MODAL_DELETE"

function DashboardQuestionOpenWidget(props: Props) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  //Hide if deleted
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  //Select message to delete and open confirmation modal
  function remove() {
    if (props.survey.active.randomData) {
      toast(t("message_delete_impossible"), { type: "error" })
    } else {
      store.dispatch(topicActivate(props.currentTopic))
      setCurrentModal(MODAL_DELETE)
    }
  }

  //Delete message in database
  //If ok remove from message list
  async function removeConfirm() {
    store.dispatch(messageStatus(STATUS_SAVING))
    const response: any = await store.dispatch(messageDestroy(props.currentMessage.aid))
    if (response.error) {
      store.dispatch(messageStatus(STATUS_SAVE_ERROR))
    } else {
      store.dispatch(
        topicEditQuestion(
          props.currentQuestion.id,
          "Messages",
          props.currentQuestion.Messages.filter((x) => x.aid !== props.currentMessage.aid)
        )
      )
      store.dispatch(
        topicEditQuestion(props.currentQuestion.id, "messagesCount", props.currentQuestion.messagesCount - 1)
      )
      /*if(props.onDelete){
        props.onDelete()
      }*/
      setIsDeleted(true)
      store.dispatch(messageStatus(STATUS_SAVED))
      setCurrentModal(null)
    }
  }

  return (
    <div
      className={"flex messages-card" + (props.currentMessage.color ? " message-exportable" : "")}
      id={props.id ? props.id : props.currentMessage.aid}>
      {currentModal === MODAL_DELETE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={removeConfirm}
          status={props.message.status}
          text={t("message_ask_delete", {
            message: props.currentMessage.text
          })}
          textBold={t("utils_next_ask")}
        />
      )}

      <div className="message-user medgrey-t">
        <FontAwesomeIcon icon={faUserCircle} />
      </div>

      {isDeleted ? (
        <div className="flex1">
          <div className="grey-t">{t("message_title", { date: props.currentMessage.dateLabel })}</div>
          <i>{t("message_deleted")}</i>
        </div>
      ) : (
        <div className="flex1 flex">
          <div className="flex1">
            <div className="grey-t">{t("message_title", { date: props.currentMessage.dateLabel })}</div>
            <div className="message-text">
              {props.search.length ? (
                <Highlighter
                  searchWords={[props.search]}
                  autoEscape={true}
                  textToHighlight={props.currentMessage.text}
                />
              ) : (
                props.currentMessage.text
              )}
            </div>
          </div>

          <div className="width-20" />

          <div>
            <ColorPickerWidget
              title=" "
              isNullAllowed
              selectedColor={props.currentMessage.color}
              colors={props._session.accountColors.tags.map((tag: Tag) => tag.color)}
              onSelect={(color) => props.onSetColor(color)}
            />
            <div className="height-20" />
            <div className="flex">
              <Space />
              {props._session.userRole === "ADMIN" && (
                <ListButton icon={faTrash} isInactive={props.survey.active.randomData} onClick={remove} />
              )}
              <Space />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  message: state.message,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardQuestionOpenWidget))
