/**
 * PROJECT-EDIT-EDIT.MODAL
 * Set end date for a project
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Modal from "@/components/modal"
import { ProjectState } from "@/redux/project.types"
import { useState } from "react"
import { store } from "@/index"
import { projectStatus } from "@/redux/project.actions"
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"
import TextInput from "@/components/text-input"
import { formatDate, updateDate } from "@/utils/format-date.utils"

interface StateProps extends WithTranslation {
  project: ProjectState
}

interface OwnProps {
  date: Date
  onClose: Function
}

type Props = StateProps & OwnProps

function ProjectEditEndModal(props: Props) {
  const { t } = props

  const [date, setDate] = useState<Date>(
    props.project.active.surveyDateEnd
      ? new Date(props.project.active.surveyDateEnd)
      : new Date(new Date().setDate(new Date().getDate() + 30))
  )

  //Proceed on next
  async function next() {
    //Save data
    store.dispatch(projectStatus(STATUS_SAVING))
    //await store.dispatch(reminderUpdate(reminder, props.project.active.id))
    store.dispatch(projectStatus(STATUS_SAVED))

    //Close modal
    props.onClose()
  }

  //Delete the date end
  async function deleteEnd() {
    /*if (props.editedReminded) {
      store.dispatch(projectStatus(STATUS_SAVING))
      await store.dispatch(reminderDestroy(props.editedReminded.id))
      store.dispatch(reminderRemove(props.editedReminded.id))
      store.dispatch(projectStatus(STATUS_SAVED))
      props.onClose()
    }*/
  }

  return (
    <Modal
      title={t("reminder_tag_end")}
      isCloseButtonVisible
      disableKeyEvents
      disableClickOutside
      status={props.project.status}
      onClose={() => props.onClose()}
      onDelete={() => deleteEnd()}
      onNext={next}>
      <TextInput
        type="date"
        isFullWidth
        onChange={(e) => setDate(updateDate(date, new Date(e.value), false))}
        title={t("sending_date")}
        value={formatDate(date, false, true)}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectEditEndModal))
